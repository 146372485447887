module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"xudong-web","previews":false,"pages":[{"type":"Blogpost","match":"/blog/:uid","component":"/opt/build/repo/src/components/templates/blogTemplate.js"},{"type":"Portfolioitem","match":"/portfolio/:uid","component":"/opt/build/repo/src/components/templates/portfolioTemplate.js"}],"sharpKeys":[{},"thumbnail"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Xudong's Personal Website","short_name":"wooxudong","description":"Xudong's personal website capturing life in film, technology and etc.","lang":"en","display":"standalone","icon":"static/image/icons/favicon.png","start_url":"/","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0cd91bd07fdb3ce2123acd8b2077d50e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
